<template>
<div>
    <national-trademark-search-status-list></national-trademark-search-status-list>
</div>
</template>

  
<script>
export default {}
</script>
